import { gql } from "@apollo/client";

export default gql`
  query ($productId: ID!) {
    product(id: $productId) {
      ...Product_Fields
      latestWorksheetItem {
        publicComment
        internalComment
      }
      location {
        id
        relationId
      }
      parts {
        ...Product_Fields
      }
    }
  }

  fragment Product_Fields on Product {
    id
    productType {
      id
      code
      description
      images
      serialCodeExpr
      serialCodeHint
      productionBatchExpr
      productionBatchHint
      isExpirationDateRequired
      isManufacturingDateRequired
      hasConsumables
      hasBrand
      majorMaintenanceInterval
      expectedLifespan
      rootCategory {
        id
        name
      }
    }
    brand
    serialCode
    productionBatch
    manufacturingDate
    expirationDate
    locationDescription
    optionalDescription
    position
    optionalProperties
    installedOn
    lastCheckedOn
    lastMajorMaintenanceOn
    replacementDate
    condition
  }
`;
