import {
  CustomerServiceOutlined,
  MailFilled,
  MailOutlined,
  PhoneFilled,
  PhoneOutlined,
  SmileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Col, Divider, Form, List, Modal, Row, Skeleton, Steps, Typography } from "antd";
import dayjs from "dayjs";
import * as React from "react";

import useAuthState from "@/authorization/use-auth-state";
import Comment from "@/components/Comment";
import Gravatar from "@/components/Gravatar";
import OptionalInformation from "@/components/OptionalInformation";
import PhoneNumber from "@/components/PhoneNumber";
import formatAddress from "@/functions/format-address";
import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import CommentEditor from "./CommentEditor";
import RelatedObjectsList from "./RelatedObjectsList";
import RelatedProductsTable from "./RelatedProductsTable";

interface IssueModalProps {
  onRelatedAppointmentClick: (appointmentId: string) => void;
  onRelatedWorksheetClick: (worksheetId: string) => void;
  onClose: () => void;
  issueId: string;
}

function InformationL({ icon, value }: { icon: React.ReactElement; value?: React.ReactNode }) {
  return (
    <div>
      {React.cloneElement(icon, { style: { fontSize: "0.9rem" } })}
      <span style={{ fontSize: "0.85rem", marginLeft: 12 }}>{value ?? "-"}</span>
    </div>
  );
}

export default function IssueModal({ onRelatedAppointmentClick, onRelatedWorksheetClick, onClose, issueId }: IssueModalProps) {
  // eslint-disable-next-line prettier/prettier
  const {
    user: { email: userEmail },
  } = useAuthState();
  const [formInstance] = Form.useForm();

  const [postCommentAsync, { loading: isPostingCommentAsync }] = useMutation(PostCommentMutation);
  const [markIssuePendingAsync, { loading: isMarkingIssuePendingAsync }] = useMutation(MarkIssuePendingMutation);
  const [resolveIssueAsync, { loading: isResolvingIssueAsync }] = useMutation(ResolveIssueMutation);
  const [assignIssueAsync, { loading: isAssigningAsync }] = useMutation(AssignIssueMutation);
  const { data } = useQuery(IssueQuery, { variables: { issueId } });
  const issue = data?.issue ?? undefined;
  const lastCommentingEmployeeId = issue?.comments[issue.comments.length - 1].postedBy.id;

  const handleOnMarkIssuePending = async () => {
    try {
      await markIssuePendingAsync({ variables: { id: issueId } });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  const handleOnSubmit = async ({ assignedEmployeeId }: { assignedEmployeeId: string }) => {
    try {
      await assignIssueAsync({ variables: { id: issueId, assignToId: assignedEmployeeId } });
      onClose();
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  const handleOnResolveIssue = async () => {
    try {
      await resolveIssueAsync({ variables: { id: issueId } });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  const handleOnSubmitComment = async (comment: string | undefined, assignEmployeeId: string | undefined) => {
    try {
      if (undefined !== assignEmployeeId) {
        await assignIssueAsync({ variables: { id: issueId, assignToId: assignEmployeeId } });
      }

      if (undefined !== comment) {
        await postCommentAsync({ variables: { id: issueId, comment } });
      }
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <Modal
      centered
      footer={
        undefined !== issue && (
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
            <Button.Group>
              {issue.status === "STATUS_RAISED" && (
                <Button loading={isMarkingIssuePendingAsync} onClick={handleOnMarkIssuePending} icon={<CustomerServiceOutlined />}>
                  Markeren als in behandeling
                </Button>
              )}
              {issue.status !== "STATUS_RESOLVED" && (
                <Button loading={isResolvingIssueAsync} onClick={handleOnResolveIssue} icon={<SmileOutlined />}>
                  Markeren als opgelost
                </Button>
              )}
            </Button.Group>
          </div>
        )
      }
      onCancel={onClose}
      styles={{
        body: {
          overflowY: "auto",
          maxHeight: window.innerHeight - 168,
        },
      }}
      title="Melding weergeven"
      open
      width="65%"
    >
      {undefined === issue ? (
        <Skeleton />
      ) : (
        <Row gutter={24} justify="space-between">
          <Col span={13}>
            <Form
              form={formInstance}
              onFinish={handleOnSubmit}
              layout="vertical"
              initialValues={{ assignedEmployeeId: issue.assignedTo?.id, publiclyAccessible: issue.publiclyAccessible }}
            >
              <h4 style={{ marginBottom: 0 }}>
                {`${issue.relation.afasCode ? `(${issue.relation.afasCode})` : ""} ${issue.relation.name}`.trim()}
              </h4>
              <div className="ant-steps-item-description" style={{ marginBottom: 4 }}>
                {issue.relation.name !== issue.location.name && <div>{issue.location.name}</div>}
                <div>{formatAddress(issue.location.address)}</div>
              </div>
              <Row justify="space-between" gutter={24} style={{ marginBottom: 10 }}>
                <Col span={12}>
                  <InformationL icon={<UserOutlined />} value={issue.location.contactPerson} />
                  <InformationL icon={<MailOutlined />} value={issue.location.primaryEmail} />
                  <InformationL
                    icon={<PhoneOutlined />}
                    value={issue.location.phoneNumber ? <PhoneNumber>{issue.location.phoneNumber}</PhoneNumber> : "-"}
                  />
                </Col>
                <Col span={12}>
                  <InformationL icon={<MailFilled />} value={issue.location.secondaryEmail} />
                  <InformationL
                    icon={<PhoneFilled />}
                    value={issue.location.mobilePhoneNumber ? <PhoneNumber>{issue.location.mobilePhoneNumber}</PhoneNumber> : "-"}
                  />
                </Col>
              </Row>
              <Alert
                type="info"
                message={
                  <Steps current={STATUS_STEPS_CURRENT[issue.status as keyof typeof STATUS_STEPS_CURRENT]} size="small">
                    <Steps.Step
                      description={dayjs(issue.createdOn).format("DD/MM/YYYY HH:mm")}
                      title="Aangekaart"
                      icon={<UserOutlined />}
                    />
                    <Steps.Step
                      description={issue.pendingOn ? dayjs(issue.pendingOn).format("DD/MM/YYYY HH:mm") : undefined}
                      title="In behandeling"
                      icon={<CustomerServiceOutlined />}
                    />
                    <Steps.Step
                      description={issue.resolvedOn ? dayjs(issue.resolvedOn).format("DD/MM/YYYY HH:mm") : undefined}
                      title="Opgelost"
                      icon={<SmileOutlined />}
                    />
                  </Steps>
                }
              />
              <>
                <List
                  header={
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                      <Typography.Title level={5} style={{ margin: 0 }}>
                        {issue.subject}
                      </Typography.Title>
                      <span>{issue.comments.length} opmerking(en)</span>
                    </div>
                  }
                  itemLayout="horizontal"
                  dataSource={issue.comments}
                  renderItem={(comment: Record<string, any>) => {
                    return (
                      <li>
                        <Comment
                          author={comment.postedBy.username}
                          avatar={<Gravatar email={comment.postedBy.email} />}
                          content={comment.text}
                          datetime={dayjs(comment.postedOn).format("LLLL")}
                        />
                      </li>
                    );
                  }}
                />
                <Comment
                  avatar={<Gravatar email={userEmail} />}
                  content={
                    <CommentEditor
                      currentlyAssignedUserId={issue.assignedTo?.id}
                      lastCommentingUserId={lastCommentingEmployeeId}
                      onSubmit={handleOnSubmitComment}
                      isPubliclyAccessible={issue.publiclyAccessible}
                      submitting={isPostingCommentAsync || isAssigningAsync}
                    />
                  }
                />
              </>
            </Form>
          </Col>
          <Col span={11}>
            {issue.relation.planningComment && (
              <OptionalInformation label="Planning notities (relatie)" value={issue.relation.planningComment} />
            )}
            {issue.relation.onSiteComment && (
              <OptionalInformation label="Planning notities (relatie)" value={issue.relation.onSiteComment} />
            )}
            {issue.location.planningComment && (
              <OptionalInformation label="Planning notities (locatie)" value={issue.location.planningComment} />
            )}
            {issue.location.onSiteComment && (
              <OptionalInformation label="Buitendienst notities (locatie)" value={issue.location.onSiteComment} />
            )}
            <Divider />
            <RelatedObjectsList
              onRelatedAppointmentClick={onRelatedAppointmentClick}
              onRelatedWorksheetClick={onRelatedWorksheetClick}
              issueId={issueId}
            />
            {Array.isArray(issue.relatedProducts) && issue.relatedProducts.length > 0 && (
              <>
                <div style={{ height: 12 }} />
                <RelatedProductsTable products={issue.relatedProducts} />
              </>
            )}
          </Col>
        </Row>
      )}
    </Modal>
  );
}

const STATUS_STEPS_CURRENT = {
  STATUS_RAISED: 0,
  STATUS_PENDING: 1,
  STATUS_RESOLVED: 2,
};

const IssueQuery = gql`
  query ($issueId: ID!) {
    issue(id: $issueId) {
      id
      relation {
        id
        name
        place
        afasCode
        planningComment
        onSiteComment
      }
      subject
      location {
        id
        name
        contactPerson
        primaryEmail
        secondaryEmail
        phoneNumber
        mobilePhoneNumber
        planningComment
        onSiteComment
        address {
          street
          city
          postalCode
          country
        }
      }
      comments {
        id
        postedBy {
          id
          username
          email
        }
        postedOn
        text
      }
      createdOn
      pendingOn
      resolvedOn
      status
      publiclyAccessible
      relatedProducts {
        id
        productType {
          id
          description
        }
        brand
        locationDescription
        serialCode
        replacementDate
      }
      assignedTo {
        id
      }
      createdBy {
        id
        username
      }
    }
  }
`;

const PostCommentMutation = gql`
  mutation ($id: ID!, $comment: String!) {
    postComment(input: { id: $id, comment: $comment }) {
      issue {
        id
        status
        comments {
          id
          postedOn
          postedBy {
            id
            username
            email
          }
          text
        }
        assignedTo {
          id
        }
      }
    }
  }
`;

const AssignIssueMutation = gql`
  mutation ($id: ID!, $assignToId: ID!) {
    assignIssue(input: { id: $id, assignToId: $assignToId }) {
      issue {
        id
        status
        assignedTo {
          id
        }
      }
    }
  }
`;

const MarkIssuePendingMutation = gql`
  mutation ($id: ID!) {
    markIssuePending(input: { id: $id }) {
      issue {
        id
        status
        pendingOn
      }
    }
  }
`;

const ResolveIssueMutation = gql`
  mutation ($id: ID!, $resolvedOn: DateTime) {
    resolveIssue(input: { id: $id, resolvedOn: $resolvedOn }) {
      issue {
        id
        status
        resolvedOn
      }
    }
  }
`;
