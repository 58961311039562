import { FileExcelOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { gql, useQuery } from "@apollo/client";
import { Button, Table } from "antd";
import dayjs from "dayjs";
import truncate from "lodash/truncate";
import * as React from "react";
import Highlighter from "react-highlight-words";

import TableFilterAutoComplete from "@/components/TableFilterAutoComplete";
import TableFilterDropdown from "@/components/TableFilterDropdown";
import downloadAttachment from "@/functions/download-attachment";
import formatAddress from "@/functions/format-address";
import formatProductDescription from "@/functions/format-product-description";
import simpleTableFilterSorter from "@/functions/simple-table-filter-sorter";

import ProductsQuery from "../../graphql/ProductsQuery";

const PAGE_SIZE = 10;

const STATUS_LIST = [
  { text: "Goed", value: "CONDITION_GOOD" },
  { text: "Actie vereist", value: "CONDITION_ACTION_REQUIRED" },
  { text: "Verwijderd", value: "CONDITION_DESTROYED" },
  { text: "Onbekend", value: "CONDITION_UNKNOWN" },
];

const DEFAULT_FILTERS = {
  locationId: undefined as string | undefined,
  locationDescription: undefined as string | undefined,
  productCategoryId: undefined as string | undefined,
  productTypeId: undefined as string | undefined,
  serialCode: undefined as string | undefined,
  condition: ["CONDITION_GOOD", "CONDITION_ACTION_REQUIRED", "CONDITION_UNKNOWN"],
};

function collectLocations(locations: Array<{ id: string; name: string; address: object }>) {
  // eslint-disable-next-line prettier/prettier
  return locations.map(l => ({ text: `${l.name} (${formatAddress(l.address as any)})`, value: l.id })).sort(simpleTableFilterSorter);
}

function collectUniqueProductCategories(productTypes: Array<{ rootCategory: { id: string; name: string } }>) {
  const categories = productTypes.map(pt => pt.rootCategory);
  const deduped = [...new Map(categories.map(c => [c.id, c])).values()];

  // eslint-disable-next-line prettier/prettier
  return deduped.map(category => ({ text: category.name, value: category.id })).sort(simpleTableFilterSorter);
}

function collectProductTypes(productTypes: Record<string, unknown>[]) {
  // eslint-disable-next-line prettier/prettier
  return productTypes.map(productType => ({ text: productType.description, value: productType.id })).sort(simpleTableFilterSorter);
}

export default function ProductsTable({
  onClick,
  onCreateClick,
  relationId,
}: {
  onClick: (productId: string) => void;
  onCreateClick: () => void;
  relationId: string;
}) {
  const [offset, setOffset] = React.useState(0);
  const [filters, setFilters] = React.useState(DEFAULT_FILTERS);
  const [routePositionSort, setRoutePositionSort] = React.useState<"ORDER_DESC" | "ORDER_ASC">();
  const [isExporting, setIsExporting] = React.useState(false);

  const { data: locationsData } = useQuery(LocationsQuery, { variables: { id: relationId } });
  const locations = (locationsData?.relation.locations ?? []).filter(l => !l.deletedAt);

  const { data: productsFiltersData } = useQuery(ProductsFiltersQuery, {
    variables: { relationId, locationId: filters.locationId },
  });
  const productTypes = productsFiltersData?.productsFilters.productTypes ?? [];
  const locationDescriptions: string[] = productsFiltersData?.productsFilters.locationDescriptions ?? [];

  const { data: productsData, loading } = useQuery(ProductsQuery, {
    variables: { ...filters, relationId, offset, limit: PAGE_SIZE, routePositionSort },
  });
  const products = productsData?.products.edges ?? [];

  const columns = React.useMemo(
    () => [
      {
        title: "Locatie",
        key: "location",
        children: [
          {
            title: "Naam",
            dataIndex: ["location"],
            filterDropdown: props => <TableFilterDropdown {...props} dataSource={collectLocations(locations)} />,
            render: location => (
              <>
                <span style={{ display: "block" }}>{location.name}</span>
                <em style={{ display: "block", marginTop: 4 }}>
                  {truncate(formatAddress(location.address), { length: 75, separator: /,? +/ })}
                </em>
              </>
            ),
          },
          {
            title: "Ophanglocatie",
            dataIndex: "locationDescription",
            render: locationDescription =>
              undefined !== filters.locationDescription && !!locationDescription ? (
                <Highlighter
                  highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                  searchWords={[filters.locationDescription]}
                  autoEscape
                  textToHighlight={locationDescription}
                />
              ) : (
                locationDescription
              ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
            filterDropdown: props => (
              <TableFilterAutoComplete
                {...props}
                dataSource={locationDescriptions.map(v => ({ label: v, value: v }))}
                onClear={() => {
                  setFilters(current => ({ ...current, locationDescription: undefined }));
                }}
                onSearch={value =>
                  setFilters(current => ({
                    ...current,
                    locationDescription: value,
                  }))
                }
              />
            ),
          },
          {
            title: "Looproute",
            key: "walkingRoute",
            sorter: true,
            render: product =>
              null !== product.route && null !== product.position ? (
                <span>{product.route + (product.position as number).toString().padStart(3, "0")}</span>
              ) : null,
          },
        ],
      },
      {
        title: "Product",
        key: "product",
        children: [
          {
            title: "Categorie",
            key: "productCategory",
            render: ({ productType }) => productType.rootCategory.name,
            filterDropdown: props => <TableFilterDropdown {...props} dataSource={collectUniqueProductCategories(productTypes)} />,
          },
          {
            title: "Type",
            key: "productType",
            render: (_, record) => {
              return (
                <span>
                  <span>{formatProductDescription(record)}</span>
                  {record.optionalDescription && <div style={{ fontStyle: "italic" }}>{record.optionalDescription}</div>}
                </span>
              );
            },
            filterDropdown: props => <TableFilterDropdown {...props} dataSource={collectProductTypes(productTypes)} />,
          },
          {
            title: "Serienummer",
            dataIndex: "serialCode",
            render: serialCode =>
              undefined !== filters.serialCode && !!serialCode ? (
                <Highlighter
                  highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                  searchWords={[filters.serialCode]}
                  autoEscape
                  textToHighlight={serialCode}
                />
              ) : (
                serialCode
              ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
            filterDropdown: props => (
              <TableFilterDropdown
                {...props}
                initialValue={filters.serialCode}
                onClear={() => {
                  setFilters(current => ({
                    ...current,
                    serialCode: undefined,
                  }));
                }}
                onSearch={value => {
                  setFilters(current => ({
                    ...current,
                    serialCode: value,
                  }));
                }}
              />
            ),
          },
        ],
      },
      {
        title: "Onderhoud",
        key: "maintenance",
        children: [
          {
            title: "Laatste controle",
            key: "lastCheckedOn",
            render: product => dayjs(product.lastCheckedOn).format("MM-YYYY"),
          },
          {
            title: "Status",
            dataIndex: "condition",
            filters: STATUS_LIST,
            render: condition => STATUS_LIST.find(s => s.value === condition)?.text ?? "Onbekend",
          },
        ],
      },
    ],
    [products, productTypes, locations]
  );

  const handleOnExportClick = async () => {
    setIsExporting(true);

    await downloadAttachment("reports/products-relation/" + relationId);
    setIsExporting(false);
  };

  return (
    <Table
      bordered
      columns={columns}
      dataSource={products}
      loading={loading}
      onChange={(pagination, filters, sorter) => {
        setOffset(((pagination.current ?? 1) - 1) * (pagination.pageSize ?? PAGE_SIZE));

        setFilters(current => ({
          ...current,
          locationId: (filters["location"]?.[0] as string) ?? undefined,
          productCategoryId: (filters["productCategory"]?.[0] as string) ?? undefined,
          productTypeId: (filters["productType"]?.[0] as string) ?? undefined,
          condition: (filters["condition"] as string[]) ?? DEFAULT_FILTERS.condition,
        }));

        if (!Array.isArray(sorter)) {
          if (sorter.columnKey === "walkingRoute") {
            if (sorter.order === undefined) return setRoutePositionSort(undefined);
            setRoutePositionSort(sorter.order === "descend" ? "ORDER_DESC" : "ORDER_ASC");
          }
        }
      }}
      onRow={React.useCallback(record => ({ onClick: () => onClick(record.id) }), [onClick])}
      rowKey={product => product.id}
      title={() => (
        <Button.Group>
          <Button onClick={onCreateClick} icon={<PlusOutlined />}>
            Product registreren
          </Button>
          <Button loading={isExporting} onClick={handleOnExportClick} icon={<FileExcelOutlined />}>
            Exporteren
          </Button>
        </Button.Group>
      )}
      pagination={{ total: productsData?.products.totalCount ?? undefined, pageSize: PAGE_SIZE }}
    />
  );
}

const LocationsQuery = gql`
  query ($id: ID!) {
    relation(id: $id) {
      id
      locations {
        id
        name
        deletedAt
        address {
          street
          postalCode
          city
          country
          coordinates {
            latitude
            longitude
          }
        }
      }
    }
  }
`;

const ProductsFiltersQuery = gql`
  query ($relationId: ID!, $locationId: ID) {
    productsFilters(relationId: $relationId, locationId: $locationId) {
      productTypes {
        id
        code
        description
        rootCategory {
          id
          name
        }
      }
      locationDescriptions
    }
  }
`;
