import { ApolloError, useMutation } from "@apollo/client";
import { Form, Modal, notification } from "antd";
import dayjs from "dayjs";
import omitBy from "lodash/omitBy";
import pickBy from "lodash/pickBy";
import { useState } from "react";

import mapGraphQLErrorsToNotifications from "@/functions/map-graphql-errors-to-notifications";

import ProductForm from "./ProductForm";
import RegisterProductMutation from "./RegisterProductMutation";

interface RegisterProductModalProps {
  onClose: () => void;
  relationId: string;
}

export default function RegisterProductModal({ onClose, relationId }: RegisterProductModalProps) {
  const [registerProductAsync, { loading: isLoading }] = useMutation(RegisterProductMutation);
  const [form] = Form.useForm();
  const [allowMissingFields, setAllowMissingFields] = useState<boolean[]>([false]);

  const handleOnChangeAllowMissingFields = (index: number, value: boolean) => {
    setAllowMissingFields(current => {
      const nextValue = [...current];
      nextValue[index] = value;

      return nextValue;
    });
  };

  const handleOnSubmit = async (values: Record<string, string | Array<string>>) => {
    try {
      const productType = values.productTypeId;
      const productTypeId =
        typeof productType === "string" ? productType : Array.isArray(productType) ? productType[productType.length - 1] : undefined;

      const defaultProps = omitBy(values, (_value, key) => key.startsWith("optionalProperties."));
      const optionalProperties_ = pickBy(values, (_value, key) => key.startsWith("optionalProperties."));
      const optionalProperties = Object.entries(optionalProperties_)
        .map(([key, value]) => ({ key: key.substring("optionalProperties.".length), value }))
        .filter(element => element.value !== undefined);

      await registerProductAsync({
        variables: {
          ...defaultProps,
          productTypeId,
          optionalProperties,
          allowMissingFields: allowMissingFields.some(value => true === value),
        },
      });

      onClose();

      notification.success({ message: "Product is geregistreerd" });
    } catch (error) {
      mapGraphQLErrorsToNotifications(error as ApolloError);
    }
  };

  return (
    <Modal
      centered
      confirmLoading={isLoading}
      bodyStyle={{
        overflowY: "auto",
        maxHeight: window.innerHeight - 168,
      }}
      onCancel={onClose}
      onOk={() => form.submit()}
      open
      title="Product registreren"
      style={{ minWidth: "35%" }}
    >
      <ProductForm
        form={form}
        onChangeAllowMissingFields={handleOnChangeAllowMissingFields}
        onSubmit={handleOnSubmit}
        initialValues={{
          installedOn: dayjs(),
          lastCheckedOn: dayjs(),
          condition: "CONDITION_GOOD",
        }}
        relationId={relationId}
      />
    </Modal>
  );
}
